import Router from "next/router"
import { useCurrentUser } from "app/lib/CurrentUser"
import PageSpinner from "app/common/PageSpinner"

function Index() {
  const currentUser = useCurrentUser()

  if (typeof window !== "undefined") {
    if (currentUser.isAuthenticated) {
      if (currentUser.organization.isChildless) {
        Router.replace(`/${currentUser?.organization?.slug}`)
      } else {
        Router.replace({
          pathname: `/${currentUser?.organization?.slug}/organizations`,
          query: {
            parentId: currentUser?.organization?.id,
          },
        })
      }
    } else {
      Router.push(`/login`)
    }
  }

  return PageSpinner()
}

Index.withNoLayout = true
export default Index
